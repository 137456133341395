import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from "./config/config.module";

@Injectable({
  providedIn: 'root'
})
export class SliderService {
  private apiUrl = AppSettings.API_URL;

  constructor(private http: HttpClient) {}

  // Get cookie value by name
  private getCookie(name: string): string {
    let ca: Array<string> = document.cookie.split(';');
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < ca.length; i += 1) {
      c = ca[i].trim();
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }

    return '';
  }

  // Get authorization headers
  private getAuthHeaders(): HttpHeaders {
    const token = this.getCookie('x_key'); // Assuming 'x_key' is the cookie name
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });
  }

  // Fetch the list of sliders
  getSliders(): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.http.get(`${this.apiUrl}admin/sliders`, { headers }); // GET request to fetch sliders with headers
  }

  // Send the new order of slider IDs to the backend
  saveOrder(orderedIds: number[]): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.http.post(`${this.apiUrl}admin/sliders/save-order`, { orderedIds }, { headers }); // POST request to save the new order with headers
  }

  // Delete a slider by its ID
  deleteSlider(sliderId: number): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.http.delete(`${this.apiUrl}admin/sliders/${sliderId}`, { headers }); // DELETE request with headers
  }

  // Create a new slider
  createSlider(sliderData: any): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.http.post(`${this.apiUrl}admin/sliders`, sliderData, { headers }); // POST request with headers
  }

  // Update an existing slider by its ID
  updateSlider(sliderId: number, sliderData: any): Observable<any> {
    const headers = this.getAuthHeaders();
    return this.http.put(`${this.apiUrl}admin/sliders/${sliderId}`, sliderData, { headers }); // PUT request with headers
  }

  toggleActive(sliderId: number) {
    const headers = this.getAuthHeaders();

    return this.http.patch(`${this.apiUrl}admin/sliders/${sliderId}/toggle-active`,{}, { headers });
  }

  getSliderById(id: string) {
    const headers = this.getAuthHeaders();

    return this.http.get(`${this.apiUrl}admin/sliders/${id}`, { headers }); // Ensure this API endpoint is correct
  }

}
