import { Component , ElementRef, ViewChild } from '@angular/core';
import { HTTPService } from '../../http.service';
import { AppSettings } from '../../config/config.module';
import { NgForm, FormGroup, FormGroupDirective } from '@angular/forms';
import { NewActionService } from './new-actions/service/new-action.service';
import { ActivatedRoute, Router } from '@angular/router'; // Added ActivatedRoute, Router for navigation

@Component({
    // moduleId: module.id,
    selector: 'content-component-articles',
    templateUrl: 'articles.component.html',
    styleUrls: [ 'articles.component.css' ],
    providers: [HTTPService, AppSettings]
})

export class ArticlesComponent{
    constructor( private _httpService: HTTPService,
      private elRef: ElementRef,
      private actionHttp:NewActionService,
      private route: ActivatedRoute,
      private router: Router
    ){
        this.getCategories();
        this.getArticles();
        this.getUsers()
        this.Frontend_url = AppSettings.FRONTEND_URL;
        // console.log(pp);
        // this.route.queryParams.subscribe(params => {
        //   if (params['author_id']) {
        //     this.selectedAuthorId = params['author_id'];
        //     this.applyAuthorFilter(this.selectedAuthorId); // Apply filter when URL changes
        //   }
        // });

    }
    @ViewChild('form') form: FormGroupDirective;
    @ViewChild('filterButton') filterButton: ElementRef<HTMLElement>;
    Frontend_url = '';
    CategoriesData: any = [];
    UsersData: any[] = [];
    ArticlesData: any = [];
    pager:any = [];
    sort = 'publish_date';

    postData: any;
    selectedAuthorId: string | null = null;
    currentFilter: any = {}; // Store the current filter data

    ngOnInit(): void {
      this.getCategories();
      this.getArticles();
      this.getUsers();

      // Prefill form fields with current filter data if available
      // this.prefillFilterForm();
    }



    sortChange(e){
        this.sort = e.target.value;
        let el: HTMLElement = this.filterButton.nativeElement;
        el.click();
    }

    Filter(e: any) {
      e.preventDefault();

      var data = {
        media_type: null,
        q: null,
        article_type: null,
        category_id: e.target.elements['category_id'].value || null,
        start_date: e.target.elements['start_date'].value || null,  // Capture start date
        end_date: e.target.elements['end_date'].value || null,    // Capture end date
        user_id: e.target.elements['user_id'].value || null
      };

      let useoption = false;

      for (var i = 0; i < e.target.elements.length; i++) {
        if (e.target.elements[i].name === 'submit') continue;

        if (e.target.elements[i].type === 'checkbox') {
          if (e.target.elements[i].checked) {
            data[e.target.elements[i].name] = 1;
            useoption = true;
          }
        } else {
          if (e.target.elements[i].value !== "") {
            if (e.target.elements[i].name === 'start_date' || e.target.elements[i].name === 'end_date') {
              let date = new Date(e.target.elements[i].value);
              let day = ("0" + date.getDate()).slice(-2);
              let month = ("0" + (date.getMonth() + 1)).slice(-2);
              let year = date.getFullYear();
              data[e.target.elements[i].name] = `${day}-${month}-${year}`;
            } else {
              data[e.target.elements[i].name] = e.target.elements[i].value;
            }
            useoption = true;
          }
        }
      }

      // Store the current filter values to be reused in pagination
      this.currentFilter = data;

      let get_data: any = false;
      if (useoption) {
        var options = ['paginate'];

        if (data.media_type != null) {
          options.push(data.media_type);
        }

        if (data.article_type != null) {
          options.push('type,' + data.article_type);
        }

        var option = options.join('|');
        get_data = 'articles?';

        let get_data_arra: any = ['include_unpublished=true'];
        get_data_arra.push('options=' + option);
        get_data_arra.push('minimal=true');

        if (data.q != null) {
          get_data_arra.push('q=' + data.q);
        }

        if (data.start_date != null) {
          get_data_arra.push('start_date=' + data.start_date);
        }

        if (data.end_date != null) {
          get_data_arra.push('end_date=' + data.end_date);
        }

        if (data.category_id != null) {
          get_data_arra.push('category_id=' + data.category_id);
        }

        if (data.user_id != null) {
          get_data_arra.push('user_id=' + data.user_id);
        }

        get_data += get_data_arra.join('&');
      }

      this.getArticles(1, get_data);
    }

    // prefillFilterForm(): void {
    //   setTimeout(() => {
    //     if (this.currentFilter) {
    //       const searchInput: HTMLInputElement = this.elRef.nativeElement.querySelector('#input_q');
    //       if (this.currentFilter.q) {
    //         searchInput.value = this.currentFilter.q;
    //       }

    //       const authorSelect: HTMLSelectElement = this.elRef.nativeElement.querySelector('#input_user_id');
    //       if (this.currentFilter.user_id) {
    //         authorSelect.value = this.currentFilter.user_id;
    //       } else {
    //         authorSelect.value = '';
    //       }

    //       const categorySelect: HTMLSelectElement = this.elRef.nativeElement.querySelector('#input_category_id');
    //       if (this.currentFilter.category_id) {
    //         categorySelect.value = this.currentFilter.category_id;
    //       } else {
    //         categorySelect.value = '';
    //       }

    //       const startDateInput: HTMLInputElement = this.elRef.nativeElement.querySelector('#start_date');
    //       if (this.currentFilter.start_date) {
    //         startDateInput.value = this.currentFilter.start_date;
    //       } else {
    //         startDateInput.value = '';
    //       }

    //       const endDateInput: HTMLInputElement = this.elRef.nativeElement.querySelector('#end_date');
    //       if (this.currentFilter.end_date) {
    //         endDateInput.value = this.currentFilter.end_date;
    //       } else {
    //         endDateInput.value = '';
    //       }
    //     }
    //   }, 100);
    // }


    getCategories(){
        this._httpService.getData('categories?withHidden=true').subscribe(
            data => this.CategoriesData = data,
            error => alert(error),
            () => this.generateCategories()
        );



    }

    generateCategories(){
        var cats = JSON.parse(this.CategoriesData._body);
        var new_data = [{id:'', title: 'Choose category' }];

        for(var i=0; i < cats.length; i++ ){
            new_data.push({ id:cats[i].id, title:cats[i].title });
            if(cats[i].children.length > 0){
                for(var j=0; j < cats[i].children.length; j++ ){
                    new_data.push({ id: cats[i].children[j].id, title: '-- '+cats[i].children[j].title });
                    if(cats[i].children[j].children.length > 0){
                        for(var ij=0; ij < cats[i].children[j].children.length; ij++ ){
                            new_data.push({ id: cats[i].children[j].children[ij].id, title: '---- '+cats[i].children[j].children[ij].title });
                        }
                    }

                }
            }
        }



        this.CategoriesData = new_data;
    }

    getArticles(page = 1, get_data: any = false) {
      if (!get_data && this.currentFilter) {
        get_data = this.buildFilterQuery();
      }
      this._httpService.getData(get_data + '&sort=' + this.sort + '&page=' + page).subscribe(
        (data) => (this.ArticlesData = data),
        (error) => alert(error),
        () => this.generateArticles()
      );
    }

    buildFilterQuery(): string {
      let get_data = 'articles?include_unpublished=true&is_admin=true&minimal=true&options=paginate|';

      if (this.currentFilter.q) get_data += '&q=' + this.currentFilter.q;
      if (this.currentFilter.start_date) get_data += '&start_date=' + this.currentFilter.start_date;
      if (this.currentFilter.end_date) get_data += '&end_date=' + this.currentFilter.end_date;
      if (this.currentFilter.category_id) get_data += '&category_id=' + this.currentFilter.category_id;
      if (this.currentFilter.user_id) get_data += '&user_id=' + this.currentFilter.user_id;

      return get_data;
    }


    generateArticles(){

        var articles = JSON.parse(this.ArticlesData._body);
        console.log(articles);
        this.ArticlesData = articles;

        this.pager = this.generatePagination(this.ArticlesData.total,this.ArticlesData.current_page,this.ArticlesData.per_page);
    }

    getUsers() {
      console.log("getUsers() method called");
      this._httpService.getData('users?select2=true').subscribe(
        (data: any) => {
          const parsedData = JSON.parse(data._body);
          console.log(parsedData);
          this.UsersData = parsedData.items || [];
        },
        (error: any) => alert('Error fetching users: ' + error)
      );
    }




    setPage(page: number) {
      this.getArticles(page);
      this.getUsers();

      // this.prefillFilterForm();
    }


    generatePagination(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage: number, endPage: number;

        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 1 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage+2;
            }
        }

        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        let pages = this.range(startPage, endPage + 1);

        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }


    range(start, stop, step = 1) {
        if (stop == null) {
          stop = start || 0;
          start = 0;
        }
        if (!step) {
          step = stop < start ? -1 : 1;
        }

        var length = Math.max(Math.ceil((stop - start) / step), 0);
        var range = Array(length);

        for (var idx = 0; idx < length; idx++, start += step) {
          range[idx] = start;
        }

        return range;
    };


    delete(id: any) {
      if (window.confirm('Are you sure you want to delete this article?')) {
          let data = { _method: 'DELETE' };

          this._httpService.postData('articles/' + id, data).subscribe(
              () => {
                  alert('Article deleted successfully!');
                  this.getCategories();
                  this.getArticles();
              },
              (error: any) => {
                  alert('Error deleting article: ' + error);
              }
          );
      } else {
          console.log('Deletion cancelled');
      }
  }


    activeActions(th){
        th.target.parentElement.parentElement.classList.toggle('show');
    }

    classCange(){
        this.actionHttp.class.emit(true);
    }

    clearFilters(form: any) {
      form.reset();
      this.currentFilter = {};
      this.getArticles();
      console.log('Filters cleared');
    }



}
