import { Component } from '@angular/core';
import { SliderService } from 'src/app/slider.service'; // Import the SliderService
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-slider-create',
  templateUrl: './slider-create.component.html',
  styleUrls: ['./slider-create.component.css']
})
export class SliderCreateComponent {
  sliderData: any = null; // If this is null, we are in "create" mode. If populated, we are in "update" mode.
  id: string | null = null;
  isCreateMode: boolean = false; // Add a flag to track if we're in create mode

  constructor(private sliderService: SliderService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    console.log()
    // Get the ID from the route parameters if available
    this.id = this.route.snapshot.paramMap.get('id');

    // Check if we're in create mode
    if (this.id === 'create') {
      this.isCreateMode = true; // If the ID is 'create', we're creating a new slider
    } else {
      // If an ID exists and is not 'create', fetch the slider data for editing
      this.isCreateMode = false;
      this.fetchSlider(this.id);
    }
  }

  fetchSlider(id: string): void {
    this.sliderService.getSliderById(id).subscribe({
      next: (response) => {
        this.sliderData = response; // Populate the form with the fetched slider data

      },
      error: (error) => {
        console.error('Error fetching slider data:', error);
      }
    });
  }
  // Handle form submission
  handleSubmit(formData: any) {
    if (this.sliderData) {
      // Update existing slider
      this.sliderService.updateSlider(this.sliderData.id, formData).subscribe({
        next: (response) => {
          this.router.navigate(['/sliders']); // Navigate to slider ID
        },
        error: (error) => {
          console.error('Error updating slider:', error);
        }
      });
    } else {
      // Create a new slider
      this.sliderService.createSlider(formData).subscribe({
        next: (response: any) => {
          console.log('Slider created successfully');
          this.router.navigate(['/sliders']); // Navigate to new slider ID
        },
        error: (error) => {
          console.error('Error creating slider:', error);
        }
      });
    }
  }

  // Method to open the link (optional, depending on how you want to handle it)
openLink(url: string) {
  window.open(url, '_blank');
}

}
