import { Component, OnInit } from '@angular/core';
import { SliderService } from 'src/app/slider.service'; // Import the service

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.css']
})
export class SlidersComponent implements OnInit {
  sliders: any[] = []; // Store the sliders fetched from the backend
  draggedItemIndex: number | null = null;
  sliderToEdit: any = null; // Store slider data for editing (null means create new)
  showForm: boolean = false; // Controls visibility of the form
  dragDropMode: boolean = false; // Add a flag for drag-drop mode

  constructor(private sliderService: SliderService) {}

  ngOnInit(): void {
    // Fetch the list of sliders when the component is initialized
    this.refreshSliders();
  }

  // Toggle form visibility for creating or updating
  openForm(slider: any = null) {
    this.sliderToEdit = slider; // If slider is provided, we are updating; otherwise, creating new
    this.showForm = true; // Show the form
  }

  // Handle form submission (create or update)
  handleFormSubmit(formData: any) {
    if (this.sliderToEdit) {
      // Update existing slider
      this.sliderService.updateSlider(this.sliderToEdit.id, formData).subscribe({
        next: (response) => {
          console.log('Slider updated successfully', response);
          this.refreshSliders(); // Refresh the slider list
          this.showForm = false; // Close the form
        },
        error: (error) => {
          console.error('Error updating slider:', error);
        }
      });
    } else {
      // Create new slider
      this.sliderService.createSlider(formData).subscribe({
        next: (response) => {
          console.log('Slider created successfully', response);
          this.refreshSliders(); // Refresh the slider list
          this.showForm = false; // Close the form
        },
        error: (error) => {
          console.error('Error creating slider:', error);
        }
      });
    }
  }

  // Refresh the list of sliders from the backend
  refreshSliders() {
    this.sliderService.getSliders().subscribe({
      next: (response) => {
        console.log(response,"Ressssss")
        this.sliders = response; // Update slider list with new data from backend
        console.log(this.sliders,"sliderrrrrrrrrrr")
      },
      error: (error) => {
        console.error('Error fetching sliders:', error);
      }
    });
  }

  onDragStart(event: DragEvent, index: number) {
    if (this.dragDropMode) {
      this.draggedItemIndex = index;
      if (event.dataTransfer) {
        event.dataTransfer.setData('text/plain', String(index));
      }
    }
  }

  onDragOver(event: DragEvent) {
    if (this.dragDropMode) {
      event.preventDefault(); // Necessary for the drop event to work
    }
  }

  onDrop(event: DragEvent, dropIndex: number) {
    if (this.dragDropMode) {
      event.preventDefault();
      const draggedIndex = this.draggedItemIndex;

      if (draggedIndex !== null) {
        this.moveItem(draggedIndex, dropIndex);
        this.saveNewOrder(); // Send updated order to the backend
      }
    }
  }

  onDragEnd() {
    this.draggedItemIndex = null;
  }

  moveItem(fromIndex: number, toIndex: number) {
    const itemToMove = this.sliders.splice(fromIndex, 1)[0]; // Remove item from current position
    this.sliders.splice(toIndex, 0, itemToMove); // Insert item at new position
  }

  // Save the new order of sliders to the backend
  saveNewOrder() {
    const orderedIds = this.sliders.map(slider => slider.id); // Get the new order of slider IDs
    this.sliderService.saveOrder(orderedIds).subscribe({
      next: (response) => {
        console.log('Order saved successfully:', response);
      },
      error: (error) => {
        console.error('Error saving order:', error);
      }
    });
  }

  // Delete a slider
  deleteSlider(sliderId: number, index: number) {
    const confirmed = confirm('Are you sure you want to delete this slider?');
    if (confirmed) {
      this.sliderService.deleteSlider(sliderId).subscribe({
        next: () => {
          console.log('Slider deleted successfully');
          this.sliders.splice(index, 1); // Remove slider from the list in the UI
        },
        error: (error) => {
          console.error('Error deleting slider:', error);
        }
      });
    }
  }

  // Open the form for editing a slider
  editSlider(slider: any) {
    this.openForm(slider); // Open form with the slider data to edit
  }


  toggleSliderStatus(slider: any) {
    if (true) {
      this.sliderService.toggleActive(slider.id).subscribe({
        next: (response) => {
          console.log(`Slider ${slider.is_active ? 'deactivated' : 'activated'} successfully`, response);
          this.refreshSliders(); // Refresh the slider list
        },
        error: (error) => {
          console.error('Error toggling slider status:', error);
        }
      });
    }
  }

  toggleDragDropMode() {
    this.dragDropMode = !this.dragDropMode; // Toggle the mode
  }

}
