import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-slider-form',
  templateUrl: './slider-form.component.html',
  styleUrls: ['./slider-form.component.css']
})
export class SliderFormComponent implements OnInit, OnChanges {
  @Input() sliderData: any = null; // Input from parent (for editing)
  @Output() formSubmit = new EventEmitter<any>(); // Emit form data to parent

  sliderForm: FormGroup;
  selectedFile: File | null = null; // Track if a file is selected

  constructor(private fb: FormBuilder) {
    // Initialize the form with necessary fields
    this.sliderForm = this.fb.group({
      // title: ['', Validators.required],   // Title field
      // date: ['', Validators.required],    // Date selection
      // photo: ['', Validators.required],   // Image URL (photo upload)
      link: ['', Validators.required],    // Link input
      // target: ['self', Validators.required] // Target (self or blank)
    });
  }

  ngOnInit(): void {
    if (this.sliderData) {
      this.patchFormWithSlider(this.sliderData); // Pre-fill form if sliderData is available

      // Remove photo validation if the data already has a photo
      // const photoControl = this.sliderForm.get('photo');
      // if (photoControl) {
      //   photoControl.clearValidators();
      //   photoControl.updateValueAndValidity();
      // }
    }
  }

  // Detect changes in the @Input property for sliderData
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sliderData && changes.sliderData.currentValue) {
      this.patchFormWithSlider(changes.sliderData.currentValue); // Patch form with new data
    }
  }

  // Patch the form with slider data
  patchFormWithSlider(slider: any): void {
    this.sliderForm.patchValue({
      // title: slider.title,
      // date: slider.date,
      link: slider.link,
      // target: slider.target,
      // photo: slider.photo || '' // If photo exists, pre-fill it
    });
  }

  // Handle form submission
  onSubmit() {
    if (this.sliderForm.valid) {
      const formData = this.sliderForm.value;

      // Only send the photo if a new file has been selected
      // if (!this.selectedFile) {
      //   delete formData.photo; // Remove photo from formData if no new file is uploaded
      // }

      this.formSubmit.emit(formData); // Emit form data to parent
    }
  }

  // Handle file selection for image upload
  // onFileSelected(event: any) {
  //   const file = event.target.files[0];
  //   if (file) {
  //     this.selectedFile = file; // Set the selected file

  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.sliderForm.patchValue({ photo: e.target.result }); // Convert image to base64 or store as needed
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // }
}
